export default {
  logoblock: {
    id: 2,
    name: "Holistic Primary Care Since 1985",
    text: "Accepting New Patients! 802.985.8250",
    created_at: "2019-08-11T15:44:47.000Z",
    updated_at: "2020-04-23T16:52:27.000Z",
    logo: {
      id: 31,
      name: "twocolorlogo.png",
      hash: "6135456507f149ac85d1a371d7494e11",
      sha256: "c6eETJZryleM2LeIIHuHTwwi3nIMXIzH7WTo1UyAsYo",
      ext: ".png",
      mime: "image/png",
      size: "32.82",
      url: "/uploads/6135456507f149ac85d1a371d7494e11.png",
      provider: "local",
      public_id: null,
      created_at: "2019-10-14T03:22:25.000Z",
      updated_at: "2019-10-14T03:22:26.000Z",
      provider_metadata: null,
    },
  },
};
