export default {
  blue: "#171544",
  sand: "#A5835A",
  black: "#1E191A",
  dkgrey: "#8A8B93",
  mdgrey: "#BABAC3",
  ltgrey: "#DBDCDE",
  white: "#f8f8f8",
  orange: "#C95D33",
  c: [
    "#FFFFFF",
    "#171544",
    "#A5835A",
    "#1E191A",
    "#8A8B93",
    "#BABAC3",
    "#DBDCDE",
    "#f8f8f8",
    "#C95D33"
  ]
};
