import Colors from "../../../utilities/colors";
export default {
  links: {
    color: Colors.blue,
    fontFamily: "Roboto",
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: "regular",
    padding: "5px",
    textDecoration: "none",
    verticalAlign: "middle"
  },
  button: {
    backgroundColor: Colors.orange,
    width: window.innerWidth,
    color: Colors.white,
    fontSize: "36px",
    fontWeight: "700"
  }
};
