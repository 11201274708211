import React from "react";
import Styles from "./style/js/style";
import { Link } from "@reach/router";
import Grid from "@material-ui/core/Grid";

const width = window.innerWidth;
const Header = ({ logoblock }) => {
  let headerStyle = {};
  if (logoblock.id === 1) {
    headerStyle = Styles.header1;
  } else headerStyle = Styles.header2;
  return (
    <Grid item xs={12}>
      <header style={{ ...headerStyle }}>
        <Link to="/">
          <img
            src={
              width > 1024
                ? "https://vtmed.dealingwiththeweb.com" + logoblock.logo.url
                : "https://vtmed.dealingwiththeweb.com/uploads/11a68ecc47e6428f8cd0f63caa350085.png"
            }
            style={{ ...headerStyle.image }}
            alt="Champlain Center For Natural Medicine"
          />
        </Link>
        {logoblock.id !== 1 ? (
          <div
            id="topLeftText"
            className="topText"
            style={{ ...headerStyle.textBlock3 }}
          >
            {logoblock.name}
          </div>
        ) : null}
        <div style={{ ...headerStyle.textBlock1 }}>{logoblock.text}</div>
      </header>
    </Grid>
  );
};

export default Header;
