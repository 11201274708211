const width = window.innerWidth;
const screen = {
  img: {
    width: "90%",
    padding: "0",
    margin: "5%"
  },
  container: {
    padding: "24px"
  },
  h1: {
    fontSize: "37px",
    display: "block"
  },
  h2: {
    fontSize: "32px",
    fontWeight: "normal",
    display: "block",
    //position: "absolute",
    top: "20%",
    width: "100%",
    textTransform: "uppercase"
  },
  boxlink: {
    padding: "5px",
    border: "0px solid",
    fontSize: "35px",
    fontWeight: "normal",
    textDecoration: "underline"
  },
  headingContainer: {
    padding: "75px 50px",
    position: "relative"
  }
};
const mobile = {
  img: {
    width: "100%",
    padding: "0"
  },
  container: {
    display: "block"
  },
  h1: {
    fontSize: "32px",
    fontWeight: "normal",
    display: "block"
  },
  h2: {
    fontSize: "24px",
    fontWeight: "normal",
    display: "block",
    top: "20%",
    width: "100%",
    textTransform: "uppercase"
  },
  boxlink: {
    padding: "5px",
    textDecoration: "underline",
    fontSize: "24px",
    fontWeight: "normal"
  },
  headingContainer: {
    padding: "75px 24px",
    position: "relative",
    fontFamily: "Heuristica",
    marginLeft: "0px",
    marginRight: "auto",
    width: "100%"
  }
};
export default {
  img: width < 1024 ? mobile.img : screen.img,
  container: width < 1024 ? mobile.container : screen.container,
  h1: width < 1024 ? mobile.h1 : screen.h1,
  h2: width < 1024 ? mobile.h2 : screen.h2,
  boxlink: width < 1024 ? mobile.boxlink : screen.boxlink,
  headingContainer:
    width < 1024 ? mobile.headingContainer : screen.headingContainer
};
