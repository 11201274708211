import React, { Component } from "react";
import Header from "../Header";
import MenuBar from "../MenuBar";
import Styles from "./style/js/style";
import Colors from "../utilities/colors";

import Grid from "@material-ui/core/Grid";

import Footer from "../Footer";
import ReactMarkdown from "react-markdown";

import MobileMenu from "../MobileMenu";

import Logoblock from "../assets/data/logoblock";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
} from "react-share";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: [],
      menu: [],
      pagerows: [null],
      pid: 1,
    };
  }

  /* */
  componentWillMount() {
    this.updatePage();
    this.getMenu();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.pid !== this.props.pid) {
      this.updatePage();
    }
  }

  /* */
  getMenu = () => {
    const url = "https://vtmed.dealingwiththeweb.com/menus/1";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          menu: data.links,
        });
      })
      .catch((error) => console.log(error));
  };
  markdownParser = (text) => {
    return <ReactMarkdown source={text} escapeHtml={false} />;
  };

  getLinkClass = (linkcolor) => {
    let linkClass = "orangeLink";
    switch (linkcolor) {
      case 1:
        linkClass = "blueLink";
        break;
      case 2:
        linkClass = "sandLink";
        break;
      case 3:
        linkClass = "blackLink";
        break;
      case 4:
        linkClass = "dkgreyLinkLink";
        break;
      case 5:
        linkClass = "mdgreyink";
        break;
      case 6:
        linkClass = "ltgreyLink";
        break;
      case 7:
        linkClass = "whiteLink";
        break;
      case 8:
        linkClass = "orangeLink";
        break;
      default:
        linkClass = "orangeLink";
    }
    return linkClass;
  };
  updatePage = () => {
    //if (this.props.pid !== 7) {
    const url =
      "https://vtmed.dealingwiththeweb.com/blogentries/" + this.props.pid;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          page: data,
          pagerows: data,
        });
      })
      .catch((error) => console.log(error));
  };
  /* */
  getLink = (id) => {
    const links = this.state.menu;
    for (let i = 0; i < links.length; i++) {
      if (links[i].id === id) {
        return links[i];
      }
    }
    return null;
  };

  /* */
  render() {
    const shareUrl = "http://vtnaturalmed.com/blogposts/" + this.state.page.id;
    const width = window.innerWidth;
    console.log(" ->", this.state.page);
    const logoblock = Logoblock.logoblock;
    return (
      <div style={Styles.bar} className="page">
        <Grid container spacing={0}>
          <Header logoblock={{ ...logoblock }} />
          {width > 1024 ? (
            <MenuBar links={this.state.menu} />
          ) : (
            <MobileMenu links={this.state.menu} />
          )}

          <Grid
            container
            sm={12}
            xs={12}
            style={{ backgroundColor: Colors.white, width: "100%" }}
          >
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid sm={0} xs={1}>
                  {""}
                </Grid>
              

                <Grid sm={0} xs={1}>
                  {""}
                </Grid>
              </Grid>
              <h1
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: "37px",
                  display: "block",
                  textTransform: "uppercase",
                  textAlign: "center",
                  fontFamily: "Hevetica",
                }}
              >
                {this.state.page.title}
              </h1>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid sm={3} xs={1}>
                  {""}
                </Grid>
                <Grid sm={6} xs={10}>
                  <div
                    style={{
                      color: "rgba(0, 0, 0, 0.84)",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      letterSpacing: "-0.084px",
                      lineHeight: "36px",
                      textRendering: "optimizelegibility",
                      wordBreak: "break-word",
                      WebkitFontSmoothing: "antialiased",
                    }}
                  >
                    {this.markdownParser(this.state.page.formattedbody)}
                  </div>
                </Grid>
                <Grid sm={3} xs={1}>
                  {""}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2}>
              <Grid sm={3} xs={1}>
                {""}
              </Grid>
              <Grid sm={6} xs={10}></Grid>
              <div className="blogentries" style={{ padding: "24px" }}>
                <FacebookShareButton
                  url={shareUrl}
                  quote={this.state.page.title}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
            </Grid>
            <Grid sm={3} xs={1}>
              {""}
            </Grid>
          </Grid>

          <Footer />
        </Grid>
      </div>
    );
  }
}

export default Blog;
//  <Grid lg={3} xs={3} item>
//               {""}
//             </Grid>
//             <Grid lg={6} xs={12} item>
//
//             </Grid>
//             <Grid lg={3} xs={3} item>
//               {""}
//             </Grid>
