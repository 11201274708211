import React from "react";
import Styles from "./style/js/style";
import Grid from "@material-ui/core/Grid";
import ReactMarkdown from "react-markdown";
import Colors from "../utilities/colors";
function HeroImage(hero) {
  return (
    <Grid
      className="HeroImage"
      style={{ position: "relative", backgroundColor: Colors.sand, width:"100%" }}
    >
      <img
        src={"https://vtmed.dealingwiththeweb.com" + hero.hero.heroimage.url}
        style={{ ...Styles.image }}
        alt=""
      />
      <div style={{ ...Styles.text }}>
        <div min="25" max="55" style={{ ...Styles.text }}>
          <ReactMarkdown source={hero.hero.textarea} escapeHtml={false} />
        </div>
      </div>
    </Grid>
  );
}

export default HeroImage;
