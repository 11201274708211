import Colors from "../../../utilities/colors";
export default {
  bar: {
    backgroundColor: Colors.orange,
    color: Colors.white,
    textAlign: "center",
    width: "100%",
    height: "60pt",
    textTransform: "capitalize",
    display: "block",
    paddingTop: "10pt",
    
  },
  links: {
    display: "inline",
    color: Colors.white,
    fontFamily: "Roboto",
    fontSize: "26.25px",
    lineHeight:"38px",
    fontWeight: "regular",
    padding: "24px",
    textDecoration: "none",
    verticalAlign: "middle"
  }
};
