import Colors from "../../../utilities/colors";
const width = window.innerWidth;
const screen = {
  image: {
    width: "100%",
    backgroundColor: Colors.orange
  },
  text: {
    color: Colors.white,
    fontFamily: "Heuristica",
    fontSize: "45px",
    fontWeight: "regular",
    position: "absolute",
    bottom: "15%",
    left: "100px",
    width: "80%"
  }
};

const mobile = {
  image: {
    width: "100%"
  },
  text: {
    fontWeight: "regular",
    backgroundColor: Colors.white,
    fontSize: "18px",
    padding: "16px 24px "
  }
};

export default {
  image: width < 1024 ? mobile.image : screen.image,
  text: width < 1024 ? mobile.text : screen.text
};
