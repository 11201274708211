import React, { useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Colors from "../utilities/colors";
import { CssTextField } from "./styles/CssTextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PDF from "@material-ui/icons/PictureAsPdf";
import Iframe from "react-iframe";

export default function ContactForm(files) {
  const [firstname, setFirstnamme] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const onSubmit = event => {
    event.preventDefault();
    const data = {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      email: email,
      message: message
    };
    fetch("https://vtmed.dealingwiththeweb.com/contactforms", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    setFirstnamme("");
    setLastname("");
    setMessage("");
    setPhone("");
    setEmail("");
  };

  const makefiles = () => {
    return files.files.map((file, index) => (
      <div>
        <a
          href={"https://vtmed.dealingwiththeweb.com" + file.file1.url}
          style={{ color: Colors.orange }}
        >
          {file.linkname} <PDF />
        </a>
      </div>
    ));
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          fontSize: "32px",
          lineHeight: "48px",
          color: Colors.white,
          backgroundColor: Colors.blue
        }}
      >
        <Container style={{ padding: "50px", boxSizing: "border-box" }}>
          <h2>New Patient Paperwork</h2>
          {makefiles()}
        </Container>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        style={{
          fontSize: "32px",
          lineHeight: "48px",
          color: Colors.white,
          backgroundColor: Colors.white
        }}
      >
        <Container style={{ padding: "50px" }}>
          <form style={{ width: "100%" }}>
            <CssTextField
              id="firstname"
              label="First Name"
              value={firstname}
              onChange={e => setFirstnamme(e.target.value)}
              onBlur={e => setFirstnamme(e.target.value)}
              variant="outlined"
              style={{ width: "75%" }}
            />
            <br /> <br />
            <CssTextField
              id="lastname"
              label="Last Name"
              value={lastname}
              onChange={e => setLastname(e.target.value)}
              onBlur={e => setLastname(e.target.value)}
              variant="outlined"
              style={{ width: "75%" }}
            />
            <br /> <br />
            <CssTextField
              id="phone"
              label="Phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              onBlur={e => setPhone(e.target.value)}
              variant="outlined"
              style={{ width: "75%" }}
            />
            <br /> <br />
            <CssTextField
              id="email"
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onBlur={e => setEmail(e.target.value)}
              variant="outlined"
              style={{ width: "75%" }}
            />
            <br /> <br />
            <label
              htmlFor="message"
              style={{
                fontFamily: "Roboto",
                fontSize: "25px",
                color: Colors.blue
              }}
            >
              Message
              <br />
              <TextareaAutosize
                id="message"
                label="message"
                aria-label="Message"
                value={message}
                rows={15}
                cols={40}
                placeholder=""
                onChange={e => setMessage(e.target.value)}
                onBlur={e => setMessage(e.target.value)}
                style={{
                  border: "3px solid " + Colors.orange,
                  backgroundColor: Colors.white,
                  color: Colors.blue,
                  fontSize: "24px",
                  width: "75%"
                }}
              />
            </label>
            <br /> <br />
            <button
              onClick={onSubmit}
              style={{
                color: Colors.white,
                backgroundColor: Colors.orange,
                fontFamily: "Roboto",
                fontSize: "35px",
                padding: "15px 30px"
              }}
            >
              Submit
            </button>
          </form>
        </Container>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          fontSize: "32px",
          lineHeight: "48px",
          color: Colors.white,
          backgroundColor: Colors.sand,
          padding: "15px"
        }}
      >
        <Container>
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4264.735359835333!2d-73.22098414838864!3d44.400736711684424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cca7c30702280a1%3A0x450d0e1561ce419c!2sChamplain%20Center%20for%20Natural%20Medicine!5e1!3m2!1sen!2sus!4v1574884975574!5m2!1sen!2sus"
            width="100%"
            height="400"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
          ></Iframe>
        </Container>
      </Grid>
    </>
  );
}
