import React from "react";
import Styles from "./style/js/style";
import { Link } from "@reach/router";

const MenuBar =(links) =>{
const menu=links.links;
  const renderItems=()=> {
    if (menu.length) {
      return menu.map((item, index) =>
        item.enable === true ? (
          <Link
            key={index}
            id={item.id}
            to={"/" + item.url}
            style={{ ...Styles.links }}
          >
            {item.name}
          </Link>
        ) : null
      );
    } else return null;
  }
  /* */
  
    
    return (
      <div style={Styles.bar} className="factoidBlock">
        {renderItems()}
      </div>
    );
  
}

export default MenuBar;
