import Colors from "../../../utilities/colors";
//const height=window.innerWidth/4.8;
const width = window.innerWidth;
//start header2 wide
const header2wide = {
  height: "170px",
  padding: "20px",
  backgroundColor: Colors.white,
  position: "relative",
  image: {
    width: "120px",
    height: "130px",
    position: "relative",
    left: "50%",
    top: "0",
    transform: "translate(-50%, 15%)"
  },
  textBlock1: {
    position: "absolute",
    right: "17%",
    top: "9%",
    fontFamily: "Roboto",
    fontSize: "calc(25px + (25 - 24) * ((100vw - 320px) / (1900 - 320)))",
    fontWeight: "bold",
    color: Colors.sand
  },
  textBlock2: {
    position: "absolute",
    right: "17%",
    top: "35%",
    textTransform: "uppercase",
    padding: "15px 40px",
    border: "2.5px solid " + Colors.orange,
    color: Colors.orange,
    fontFamily: "Roboto",
    fontSize: "calc(35px + (35 - 25) * ((100vw - 320px) / (1900 - 320)))",
    fontWeight: "bold"
  },
  textBlock3: {
    position: "absolute",
    width: "25%",
    left: "9%",
    top: width >= 900 ? "9%" : "35%",
    fontFamily: "Roboto",
    fontSize: "calc(28px + (28 - 27) * ((100vw - 320px) / (1900 - 320)))",
    fontWeight: "normal",
    color: Colors.sand
  }
};

const header2mobile = {
  height: "100%",

  backgroundColor: Colors.white,
  position: "relative",
  padding: "5%",
  image: {
    width: "66%",
    position: "relative",
    float: "left",
    marginLeft: 0,
    padding: "24px"
  },
  textBlock1: {
    display: "none"
  },
  textBlock2: {
    display: "none"
  },
  textBlock3: {
    display: "none"
  }
};
const header1wide = {
  height: "410px",
  padding: "20px",
  backgroundColor: Colors.white,
  position: "relative",
  image: {
    width: "auto",
    height: "255px",
    position: "relative",
    left: "50%",
    top: "75px",
    transform: "translate(-50%, 15%)"
  },
  textBlock1: {
    position: "absolute",
    right: "9%",
    top: "9%",
    fontFamily: "Roboto",
    fontSize: "27.5px",
    fontWeight: "bold",
    color: Colors.sand
  },
  textBlock2: {
    position: "absolute",
    right: "9%",
    top: "22%",
    textTransform: "uppercase",
    padding: "15px 40px",
    border: "2.5px solid " + Colors.orange,
    color: Colors.orange,
    fontFamily: "Roboto",
    fontSize: "32.5px",
    fontWeight: "bold"
  }
};
export default {
  header1: width < 1024 ? header2mobile : header1wide,
  header2: width < 1024 ? header2mobile : header2wide
};
