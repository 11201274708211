import React, { Component, Fragment } from "react";
import Header from "../Header";
import MenuBar from "../MenuBar";
import HeroImage from "../HeroImage";
import Styles from "./style/js/style";
import Colors from "../utilities/colors";
import Fonts from "../utilities/fonts";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Footer from "../Footer";
import ReactMarkdown from "react-markdown";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "@reach/router";
import MobileMenu from "../MobileMenu";
import ContactForm from "../ContactForm";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: [],
      menu: [],
      pagerows: [null],
      pid: 1,
      files: [],
    };
  }

  /* */
  componentWillMount() {
    this.updatePage();
    this.getMenu();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.pid !== this.props.pid) {
      this.updatePage();
    }
  }

  /* */
  getMenu = () => {
    const url = "https://vtmed.dealingwiththeweb.com/menus/1";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          menu: data.links,
        });
      })
      .catch((error) => console.log(error));
  };
  markdownParser = (text) => {
    return <ReactMarkdown source={text} escapeHtml={false} />;
  };
  imageRow = (item) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        style={{ backgroundColor: Colors.c[item.bgcolor] }}
      >
        <img
          src={"https://vtmed.dealingwiththeweb.com/" + item.image.url}
          alt=""
          style={{ ...Styles.img }}
        />
      </Grid>
    );
  };
  fullWidth = (item, sm) => {
    const lineHeight = item.fontsize + item.fontsize / 2;
    return (
      <Grid
        item
        xs={12}
        sm={sm}
        style={{
          color: Colors.c[item.fontcolor],
          backgroundColor: Colors.c[item.bgcolor],
          fontSize: window.innerWidth < 1024 ? "18px" : item.fontsize + "px",
          lineHeight: window.innerWidth < 1024 ? "32px" : lineHeight + "px",
          padding: window.innerWidth < 1024 ? "24px" : "50px 0px",
          fontFamily: Fonts.family[item.font],
        }}
      >
        <Container>
          {typeof item.heading !== "undefined" && item.heading !== null ? (
            <h2
              style={{
                ...Styles.h2,
                color: Colors.c[item.fontcolor],
                fontFamily: Fonts.family[item.font],
              }}
            >
              {item.heading}
            </h2>
          ) : null}
        </Container>
        <Container
          className={this.getLinkClass(item.linkcolor)}
          style={{ ...Styles.container }}
        >
          {this.markdownParser(item.textarea)}
        </Container>
      </Grid>
    );
  };
  collapsibleRow = (item) => {
    const lineHeight = item.fontsize + item.fontsize / 2;
    return (
      <Grid
        item
        xs={12}
        style={{
          color: Colors.c[item.fontcolor],
          backgroundColor: Colors.c[item.bgcolor],
          fontSize: window.innerWidth < 1024 ? "18px" : item.fontsize + "px",
          lineHeight: window.innerWidth < 1024 ? "36px" : lineHeight + "px",
          fontFamily: Fonts.family[item.font],
        }}
      >
        <ExpansionPanel style={{ backgroundColor: Colors.c[item.bgcolor] }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: "55px" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {typeof item.heading !== "undefined" && item.heading !== null ? (
              <Container>
                <h2
                  style={{
                    ...Styles.h2,
                    color: Colors.sand,
                    fontFamily: Fonts.family[item.font],
                  }}
                >
                  {item.heading}
                </h2>
              </Container>
            ) : null}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Container>{this.markdownParser(item.textarea)}</Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  };
  getLinkClass = (linkcolor) => {
    let linkClass = "orangeLink";
    switch (linkcolor) {
      case 1:
        linkClass = "blueLink";
        break;
      case 2:
        linkClass = "sandLink";
        break;
      case 3:
        linkClass = "blackLink";
        break;
      case 4:
        linkClass = "dkgreyLinkLink";
        break;
      case 5:
        linkClass = "mdgreyink";
        break;
      case 6:
        linkClass = "ltgreyLink";
        break;
      case 7:
        linkClass = "whiteLink";
        break;
      case 8:
        linkClass = "orangeLink";
        break;
      default:
        linkClass = "orangeLink";
    }
    return linkClass;
  };
  updatePage = () => {
    //if (this.props.pid !== 7) {
    const url = "https://vtmed.dealingwiththeweb.com/pages/" + this.props.pid;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          page: data,
          pagerows: data.textrows,
          files: data.filesrows,
          decoImage: data.undefined,
        });
      })
      .catch((error) => console.log(error));
  };
  /* */
  getLink = (id) => {
    const links = this.state.menu;
    for (let i = 0; i < links.length; i++) {
      if (links[i].id === id) {
        return links[i];
      }
    }
    return null;
  };
  sortPageRows = () => {
    let rows = this.state.pagerows.sort(function(obj1, obj2) {
      return obj1.order - obj2.order;
    });
    return rows;
  };
  getTextCol = (item) => {
    let link = {};
    if (typeof item.link !== "undefined") {
      link = this.getLink(item.link);
    }

    return (
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          backgroundColor: Colors.c[item.bgcolor],
          ...Styles.headingContainer,
          fontFamily: Fonts.family[item.font],
        }}
      >
        {typeof item.heading !== "undefined" ? (
          <h2
            style={{
              ...Styles.h2,
              color: Colors.c[item.fontcolor],
              fontFamily: Fonts.family[item.font],
            }}
          >
            {item.heading}
          </h2>
        ) : null}
        {typeof item.textarea !== "undefined" ? (
          <div
            style={{
              color: Colors.c[item.fontcolor],
              fontFamily: Fonts.family[item.font],
              fontSize: item.fontsize,
              fontWeight: "normal",
            }}
          >
            {this.markdownParser(item.textarea)}
          </div>
        ) : null}

        {item.buttontext !== null && link !== null ? (
          <div>
            <Link
              to={link.url !== null ? link.url : "/"}
              style={{ ...Styles.boxlink, color: Colors.c[item.linkcolor] }}
            >
              {item.buttontext}
            </Link>
          </div>
        ) : null}
      </Grid>
    );
  };
  makerow(item) {
    if (typeof item === "undefined" || item == null) return null;
    if (item.fullwidth === true) {
      if (item.collapsible === false) {
        return <Fragment>{this.fullWidth(item, 12)}</Fragment>;
      } else {
        return <Fragment>{this.collapsibleRow(item)}</Fragment>;
      }
    } else if (typeof item.image !== "undefined") {
      if (item.imageposition === "left") {
        return (
          <Fragment>
            {this.imageRow(item)}
            {this.getTextCol(item)}
          </Fragment>
        );
      } else if (item.imageposition === "right") {
        return (
          <Fragment>
            {this.getTextCol(item)}
            {this.imageRow(item)}
          </Fragment>
        );
      } else return null;
    } else if (item.fullwidth !== true) {
      return <Fragment>{this.fullWidth(item, 6)}</Fragment>;
    } else return null;
  }
  renderItems() {
    const rows = this.sortPageRows();
    if (typeof rows !== "undefined") {
      return rows.map((item, index) => (
        <Fragment key={index}>{this.makerow(item)}</Fragment>
      ));
    }
  }
  /* */
  render() {
    const width = window.innerWidth;
    //if (this.props.pid === "7") return <ContactForm />;
    return (
      <div style={Styles.bar} className="page">
        <Grid container spacing={0}>
          {this.state.page.logoblock !== null &&
          typeof this.state.page.logoblock !== "undefined" ? (
            <Header logoblock={this.state.page.logoblock} />
          ) : null}
          {window.innerWidth > 1024 ? (
            <MenuBar links={this.state.menu} />
          ) : (
            <MobileMenu links={this.state.menu} />
          )}

          <Grid
            item
            sm={12}
            style={{ backgroundColor: Colors.white, width: "100%" }}
          >
            <Container>
              {this.state.page.name !== "Home Page" ? (
                <h1
                  style={{
                    ...Styles.h1,
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {this.state.page.name}
                </h1>
              ) : null}
            </Container>
          </Grid>

          {this.state.page.herorow !== null &&
          typeof this.state.page.herorow !== "undefined" ? (
            <HeroImage
              hero={this.state.page.herorow}
              text={this.markdownParser(this.state.page.herorow.textarea)}
            />
          ) : null}

          {this.renderItems()}
          {this.props.pid === "7" ? (
            <ContactForm files={this.state.files} />
          ) : null}
          <Footer />
        </Grid>
      </div>
    );
  }
}

export default Page;
