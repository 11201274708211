import React from "react";
import Page from "../Page";
import { Router } from "@reach/router";
import "./style/css/App.css";
import BlogPage from "../BlogPage";
import Blog from "../Blog";
function App() {
  return (
    <div className="App">
      <Router>
        <Page path="/" pid="1" />
        <Page path="/doctors" pid="2" id="2" />
        <Page path="/residents" pid="3" />
        <Page path="/services" pid="4" />
        <Page path="/faqs" pid="5" />
        <Page path="/resources" pid="6" />
        <Page path="/testimonials" pid="8" />
        <Page path="/contact" pid="7" />
        <Page path="/content/dr-bill-warnock" pid="2" />
        <Page path="/content/meet-dr-susan-jacobs" pid="2" />
        <Page path="/content/dr-choying-joyce-lee-nd" pid="2" />
        <Page path="/content/dr-tara-pollock-nd" pid="2" />
        <Page path="/coronavirus" pid="13" />
        <Page path="/portal" pid="15" />
        <Blog path="/blogposts/:pid" />
        <BlogPage path="/doctorsblogs" pid="3" />
      </Router>
    </div>
  );
}

export default App;
