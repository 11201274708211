import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../utilities/colors";
export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      borderBottomColor: Colors.blue,
      border: "0px solid",
      width: "95%"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.blue,
      border: "3px solid",
      width: "95%"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.orange,
        border: "3px solid",
        width: "95%"
      },
      "&:hover fieldset": {
        borderColor: Colors.sand,
        border: "3px solid",
        width: "95%"
      },
      "&.Mui-focused fieldset": {
        borderColor: Colors.blue,
        border: "3px solid",
        width: "95%px"
      }
    }
  }
})(TextField);
