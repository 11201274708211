import Colors from "../../../utilities/colors";
const width = window.innerWidth;
const screen = {
  //height: "450px",
  padding: "100px",
  backgroundColor: Colors.blue,
  position: "relative",
  color: Colors.white,
  fontSize: "27px",
  fontFamily: "Roboto"
};
const tablet = {
  //height: "450px",
  padding: "100px",
  backgroundColor: Colors.blue,
  position: "relative",
  color: Colors.white,
  fontSize: "27px",
  fontFamily: "Roboto"
};
const mobile = {
  backgroundColor: Colors.blue,
  position: "relative",
  color: Colors.white,
  fontSize: "24px",
  lineHeight: "36px",
  fontFamily: "Roboto",
  width: "100%",
  padding: "24px"
};
export default {
  footer: width < 1024 ? mobile : width < 1900 ? tablet : screen
};
