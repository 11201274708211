import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "@reach/router";
import Styles from "./style/js/style";
export default function MobileMenu({ links }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const makeMenu = () => {
    return links.map((item, index) =>
      item.enable === true ? (
        <MenuItem key={index} onClick={handleClose}>
          <Link
            key={index}
            id={item.id}
            to={"/" + item.url}
            style={{ ...Styles.links }}
          >
            {item.name}
          </Link>
        </MenuItem>
      ) : null
    );
  };
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ ...Styles.button }}
      >
        Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {makeMenu()}
      </Menu>
    </div>
  );
}
