import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Styles from "./style/js/style";
import ReactMarkdown from "react-markdown";
import Grid from "@material-ui/core/Grid";
class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
      data: [],
      
    };
  }
  componentWillMount() {
     
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.pid !== this.props.pid) {
      this.getData();
    }
  }
  getData = () => {
    const url = "https://vtmed.dealingwiththeweb.com/footers/1";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          data
        });
      })
      .catch((error) => console.log(error));
      
  };
  markdownParser = (text) => {
    return <ReactMarkdown source={text} escapeHtml={false} />;
  };

  createFooter() {
   
   
    
    return (
      <Fragment>
        <Grid item sm={6} xs={12} style={{ ...Styles.footer }}>
          <Container>
          {this.markdownParser(this.state.data.lefttextfield)}
          </Container>
        </Grid>
        <Grid item sm={6} xs={12} style={{ ...Styles.footer }}>
          <Container>
          {this.markdownParser(this.state.data.righttextfield)}
          </Container>
        </Grid>
      </Fragment>
    );
  }

  render() {
    return this.createFooter();
  }
}

export default Footer;
